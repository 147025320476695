<template>
  <div v-if="showBlock" class="group-games group-games--double">
    <atomic-icon v-if="titleIcon" :id="titleIcon" filled />

    <h2 class="title">
      {{ gameCategoriesObj[props.category.identity]?.label || props.category.name || props.category.identity }}
    </h2>

    <nuxt-link class="btn-show-all" :to="showAllLink">
      {{ getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'cardsGroup.moreButton') }}
    </nuxt-link>

    <button-arrows
      v-if="showArrowButtons"
      :prev-disabled="prevDisabled"
      :next-disabled="nextDisabled"
      @click-action="clickAction"
    />

    <div
      ref="scrollContainer"
      class="items"
      :class="{ 'disabled-scroll-block': !games.length }"
      @scroll="scrollHandler"
    >
      <template v-if="games.length">
        <card-base v-for="(game, gameIndex) in games" ref="cards" :key="gameIndex" :game-info="game" />
      </template>

      <template v-else>
        <div v-for="n in 36" :key="n" class="card-base">
          <div class="card-base__preview" />
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { ICollection, IGame, IPaginationMeta } from '~/types';
  import { storeToRefs } from 'pinia';

  const props = defineProps<{
    category: ICollection;
  }>();

  const globalStore = useGlobalStore();
  const { globalComponentsContent, defaultLocaleGlobalComponentsContent, gameCategoriesObj } = globalStore;
  const { headerCountry } = storeToRefs(globalStore);
  const { getContent, localizePath, initObserver } = useProjectMethods();
  const titleIcon = gameCategoriesObj[props.category.identity]?.icon;

  const scrollContainer = ref();
  const prevDisabled = ref<boolean>(true);
  const nextDisabled = ref<boolean>(true);
  const showArrowButtons = ref<boolean>(true);
  const games = ref<IGame[]>([]);
  const pageMeta = ref<IPaginationMeta>();
  const { getFilteredGames } = useCoreGamesApi();

  const observerLoadMore = ref();
  const lastItem = ref();
  const cards = ref([]);

  const scrollHandler = (): void => {
    if (!scrollContainer.value) return;
    const { scrollLeft, offsetWidth, scrollWidth } = scrollContainer.value;
    prevDisabled.value = scrollLeft === 0;
    nextDisabled.value =
      scrollWidth < scrollLeft + offsetWidth + 20 &&
      scrollWidth > scrollLeft + offsetWidth - 20 &&
      pageMeta.value?.page === pageMeta.value?.totalPages;
  };

  const clickAction = (direction: string): void => {
    const { offsetWidth, scrollWidth, scrollLeft } = scrollContainer.value;
    const widthToEnd = scrollWidth - (scrollLeft + offsetWidth);
    const scrollLeftValue = widthToEnd < offsetWidth ? widthToEnd : offsetWidth;
    const scrollRightValue = scrollLeft < offsetWidth ? scrollLeft : offsetWidth;
    scrollContainer.value.scrollBy({
      left: direction === 'next' ? scrollLeftValue + 12 : -scrollRightValue - 12,
      behavior: 'smooth',
    });
  };

  const defaultRequestParams = {
    collectionId: [props.category.id],
    countries: headerCountry.value ? [headerCountry.value] : undefined,
    sortBy: 'default',
    sortOrder: 'asc',
  };

  const moreGames = async (): Promise<void> => {
    if (pageMeta.value?.page === pageMeta.value?.totalPages) return;

    const gamesResponse = await getFilteredGames({
      ...defaultRequestParams,
      perPage: window.innerWidth < 768 ? 24 : 36,
      page: pageMeta.value ? pageMeta.value.page + 1 : 1,
    });

    games.value = games.value.concat(gamesResponse.data);
    pageMeta.value = gamesResponse.meta;
  };

  const showBlock = ref<boolean>(true);
  const unobserveLastItem = (): void => {
    if (lastItem.value) {
      observerLoadMore.value.unobserve(lastItem.value.$el);
      lastItem.value.$el.removeEventListener('inview', moreGames);
    }
  };

  const observeLastItem = (): void => {
    unobserveLastItem();

    lastItem.value = cards.value[cards.value?.length - 1];
    if (lastItem.value) {
      observerLoadMore.value.observe(lastItem.value.$el);
      lastItem.value.$el.addEventListener('inview', moreGames);
    }
  };

  const route = useRoute();
  const showAllLink = computed(() => {
    const routeName = route.name as string;
    if (routeName.includes('live-casino')) return localizePath(`/live-casino/${props.category.identity}`);
    return localizePath(`/casino/${props.category.identity}`);
  });

  watch(games, async () => {
    if (observerLoadMore.value) {
      await nextTick();
      observeLastItem();
    }
  });

  onMounted(async () => {
    const gamesResponse = await getFilteredGames({
      ...defaultRequestParams,
      perPage: window.innerWidth < 768 ? 24 : 36,
    });
    if (!gamesResponse.data.length) return (showBlock.value = false);
    games.value = gamesResponse.data;
    pageMeta.value = gamesResponse.meta;

    await nextTick();
    scrollHandler();
    showArrowButtons.value = !prevDisabled.value || !nextDisabled.value;

    observerLoadMore.value = initObserver({
      settings: { root: scrollContainer.value, rootMargin: '90%', threshold: 0 },
    });
    observeLastItem();
  });

  onBeforeUnmount(() => {
    if (observerLoadMore.value) unobserveLastItem();
  });
</script>

<style src="~/assets/styles/components/group/games.scss" lang="scss" />
