<template>
  <div class="arrows">
    <button-base
      :key="'prev'"
      type="secondary-3"
      class="btn-prev"
      size="xs"
      :isDisabled="props.prevDisabled"
      data-action="prev"
      @click="clickNavigation('prev')"
    >
      <atomic-icon id="arrow-navigate-before" />
    </button-base>

    <button-base
      :key="'next'"
      type="secondary-3"
      size="xs"
      class="btn-next"
      :isDisabled="props.nextDisabled"
      data-action="next"
      @click="clickNavigation('next')"
    >
      <atomic-icon id="arrow-navigate-next" />
    </button-base>
  </div>
</template>

<script setup lang="ts">
  const props = defineProps({
    prevDisabled: {
      type: Boolean,
      default: true,
    },
    nextDisabled: {
      type: Boolean,
      default: false,
    },
  });
  const emit = defineEmits(['clickAction']);
  const clickNavigation = (direction: string): void => {
    if ((direction === 'next' && props.nextDisabled) || (direction === 'prev' && props.prevDisabled)) return;
    emit('clickAction', direction);
  };
</script>

<style src="~/assets/styles/components/button/arrows.scss" lang="scss" />
